import axios from 'axios'
import router from '../router'
import { Notify } from 'vant'
import storage from 'store'

axios.defaults.timeout = 70000
axios.defaults.retry = 4
axios.defaults.retryDelay = 1000
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'

// 线上
// axios.defaults.baseURL = 'https://apigdh.yikeyiqun.cn/one/v1/'
//一客一群old
//axios.defaults.baseURL = 'https://gapi.yikeyiqun.cn/one/v1/'
//一客一群
//axios.defaults.baseURL = 'https://qapi.yikeyiqun.cn/one/v1/'
//深圳
//axios.defaults.baseURL = 'https://szapi.yikeyiqun.cn/one/v1/'
//思索
 axios.defaults.baseURL = 'https://api.yikeyiqun.cn/one/v1/'
//喻总
//axios.defaults.baseURL = 'https://yuapi.yikeyiqun.cn/one/v1/'
//兔宝宝
//axios.defaults.baseURL = 'https://tbbapi.huiquancai.cn/one/v1/'
//乐尚喻总
//axios.defaults.baseURL = 'https://api.lszzjj.com/one/v1/'
//兔宝宝-提莫整家
//axios.defaults.baseURL = 'https://tmzjapi.huiquancai.cn/one/v1/'
//兔宝宝-测试
//axios.defaults.baseURL = 'https://tbbapi.yikeyiqun.cn/one/v1/'
//兔宝宝-怡居科材
//axios.defaults.baseURL = 'https://yjkcapi.yjkc.top/one/v1/'


// POST传参序列化
axios.interceptors.request.use(
  (config) => {
    let merchant_id = storage.get('MERCHANT_ID')

    //全局追加商户号
    config.params = Object.assign(config.params ? config.params : {}, {
      merchant_id: merchant_id,
    })

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 返回状态判断
axios.interceptors.response.use(
  (response) => {
    if (response.data.code != '200' && response.data.message) {
      Notify({ type: 'danger', message: response.data.message })
      console.log('axios.response', response.data.message)
      return Promise.reject(response)
    }
    return response
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          router.push({
            path: '/',
            query: { redirect: router.currentRoute.fullPath },
          })
          break
        case 422:
          console.log(error.response)
      }
    }

    var config = error.config
    // If config does not exist or the retry option is not set, reject
    if (!config || !config.retry) return Promise.reject(error)

    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0

    // Check if we've maxed out the total number of retries
    if (config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(error)
    }

    // Increase the retry count
    config.__retryCount += 1

    // Create new promise to handle exponential backoff
    var backoff = new Promise(function(resolve) {
      setTimeout(function() {
        resolve()
      }, config.retryDelay || 1)
    })

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function() {
      return axios(config)
    })
  }
)

/**
 * POST 请求
 *
 * @param {*} url
 * @param {*} params
 */
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(
        (response) => {
          resolve(response.data)
        },
        (err) => {
          reject(err)
        }
      )
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * 获取请求
 *
 * @param {*} url
 * @param {*} params
 */
export function fetchGet(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: params })
      .then(
        (response) => {
          resolve(response.data)
        },
        (err) => {
          reject(err)
        }
      )
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * 更新请求
 *
 * @param {*} url
 * @param {*} params
 */
export function fetchPut(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(
        (response) => {
          resolve(response.data)
        },
        (err) => {
          reject(err)
        }
      )
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * 删除请求
 *
 * @param {*} url
 * @param {*} params
 */
export function fetchDel(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { params: params })
      .then(
        (response) => {
          resolve(response.data)
        },
        (err) => {
          reject(err)
        }
      )
      .catch((error) => {
        reject(error)
      })
  })
}
