import storage from 'store'
import { SIGN, SIGN_ID, UPLOAD } from '../mutation-types'
import { fetchPost, fetchGet } from '@/utils/axios'

const state = {
  sign_id: 0,
  sign: {},
  upload: {},
}

const mutations = {
  [SIGN_ID]: (state, id) => {
    state.sign_id = id
    storage.set(SIGN_ID, id)
  },
  [SIGN]: (state, res) => {
    state.sign = res
  },
  [UPLOAD]: (state, res) => {
    state.upload = res
  },
}

const actions = {
  setSignId({ commit }, id) {
    return commit(SIGN_ID, id)
  },
  getSign({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchGet(`take/view`, params)
        .then(
          (res) => {
            commit(SIGN, res.data)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
        .catch((e) => {
          reject(e)
        })
    })
  },
  onGuestSign({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchPost(`file/base64`, params)
        .then((res) => {
          commit(UPLOAD, res.data)
          params.sign = res.data.url
          delete params.image
          fetchPost(`take/guest-sign`, params)
            .then((res) => {
              commit(SIGN, res)
              resolve(res)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
}
