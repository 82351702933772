import storage from 'store'
import { TAKE, TAKE_ID, MERCHANT_ID } from '../mutation-types'
import { fetchPost, fetchGet } from '@/utils/axios'

const state = {
  take_id: 0,
  merchant_id: 0,
  take: {},
}

const mutations = {
  [TAKE_ID]: (state, id) => {
    state.take_id = id
    storage.set(TAKE_ID, id)
  },
  [TAKE]: (state, res) => {
    state.take = res
  },
  [MERCHANT_ID]: (state, id) => {
    state.merchant_id = id
    storage.set(MERCHANT_ID, id)
  },
}

const actions = {
  setMerchantId({ commit }, id) {
    return commit(MERCHANT_ID, id)
  },
  getTake({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchGet(`take/view`, params)
        .then(
          (res) => {
            commit(TAKE, res.data)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
        .catch((e) => {
          reject(e)
        })
    })
  },
  // 确认数据
  checkTime({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchPost(`pre/check`, params)
        .then((res) => {
          commit(TAKE, res)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  cancelTime({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchPost(`pre/cancel`, params)
        .then((res) => {
          commit(TAKE, res)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  updatelTime({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchPost(`pre/edit`, params)
        .then((res) => {
          commit(TAKE, res)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
}
