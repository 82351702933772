import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PreView from '@/views/pre/View.vue'
import PreCheck from '@/views/pre/Check.vue'
import SignView from '@/views/sign/View.vue'
import GuestSign from '@/views/sign/GuestSign.vue'
import TakeView from '@/views/take/View.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/pre',
    name: 'PreView',
    component: PreView,
  },
  {
    path: '/pre/check',
    name: 'PreCheck',
    component: PreCheck,
  },
  {
    path: '/sign',
    name: 'SignView',
    component: SignView,
  },
  {
    path: '/sign/guest',
    name: 'GuestSign',
    component: GuestSign,
  },
  {
    path: '/take',
    name: 'TakeView',
    component: TakeView,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
