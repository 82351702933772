<template>
  <div id="take-view">
    <van-nav-bar :title="item.cate_name" />

    <div class="pre-view-body">
      <van-cell>
        <template #title>{{item.content}}</template>
      </van-cell>
      <van-cell-group>
        <van-field readonly clickable label="提交时间" :value="timeValue +'   ' + week" />
      </van-cell-group>
      <div v-if="item.covers">
          <van-image :src="image" v-for="(image, index) in item.covers" :key="index">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
            <template v-slot:error>加载失败</template>
          </van-image>
      </div>
      <video width="100%" height="300" :src="item.video" v-if="item.video"></video>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import moment from 'moment'
export default {
  name: 'TakeView',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data() {
    return {
      id: 0,
      merchant_id: 0,
      iswork: false,
      issend: false,
      guest_id: 0,
      timeValue: '',
      timeValueEnd: '',
      week: '',
      show: false,
      time: '',
      item: {},
      minDate: new Date(),
      cate: {},
    }
  },
  mounted: function () {
    this.id = this.$route.query.id
    this.merchant_id = this.$route.query.merchant_id
    // this.$store.dispatch('setMerchantId', this.merchant_id)
    this.getTake()
    // this.iswork = this.isWechat()
  },
  methods: {
    isWechat() {
      var ua = navigator.userAgent.toLowerCase()
      console.log('ua.match(/wxwork/i)', ua.match(/wxwork/i))
      if (ua.match(/wxwork/i) == 'wxwork') {
        return true
      }
      return false
    },
    onChange(index) {
      this.index = index;
    },
    getTake() {
      this.$store
        .dispatch('getTake', {
          id: this.id,
          merchant_id: this.merchant_id,
          expand: 'cate',
        })
        .then((res) => {
          this.cate = res.cate
          moment.locale('zh-cn')
          
          this.timeValue = moment.unix(res.created_at).format('YYYY-MM-DD')

          this.week = moment.unix(res.created_at).format('dddd')
          this.item = res
          this.guest_id = res.guest_id
        })
    },
    // 时间过滤
    filter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 10 === 0)
      }
      return options
    },
    // 出错提示
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },

    onCheckTime() {
      if (this.issend) false
      this.issend = true
      var params = {
        id: this.id,
        guest_id: this.guest_id,
        merchant_id: this.merchant_id,
      }
      this.$store.dispatch('checkTime', params).then((res) => {
        console.log('res', res)
        this.issend = false
        this.$notify({ type: 'success', message: res.message })
        this.getTake()
      })
    },
    onCancelTime() {
      var params = {
        id: this.id,
        guest_id: this.guest_id,
        merchant_id: this.merchant_id,
      }
      var that = this
      this.$store.dispatch('cancelTime', params).then((res) => {
        console.log('res', res)

        that.$notify({ type: 'success', message: res.message })
        that.getTake()
      })
    },

    // 显示弹窗
    showPopup() {
      this.show = true
      this.isLoadingShow = true
      setTimeout(() => {
        this.isLoadingShow = false
      }, 500)
    },

    // 确认选择的时间
    confirmPicker(val) {
      moment.locale('zh-cn')
      this.timeValue = moment(val).format('YYYY-MM-DD ')

      this.week = moment.unix(val).format('dddd')

      this.show = false
      this.isValue = true
    },
  },
}
</script>

<style lang="less" scoped>
.pre-view-body {
  padding: 20px 20px;
  .van-button {
    margin: 20px 0;
  }
}
</style>
