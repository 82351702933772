<template>
  <div id="pre-view">
    <van-nav-bar title="预约" />

    <div class="pre-view-body">
      <van-cell>
        <template #title>{{item.content}}</template>
      </van-cell>

      <van-form validate-first @failed="onFailed" style="margin-bottom:50px;">
        <van-cell-group>
          <van-field readonly clickable label="预约时间" :value="timeValue +'   ' + week" />
          <van-field
            readonly
            clickable
            v-if="item.end_time > 0"
            label="结束时间"
            :value="timeValueEnd+'   ' + week2"
          />
          <van-field readonly clickable label="预约状态" :value="item.pre_status_name" />
        </van-cell-group>
      </van-form>

      <div v-show="!iswork">
        <div v-show="item.pre_status == '0'">
          <van-button type="primary" @click="onCheckTime" :disabled="issend" block>确认</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PreView',
  data() {
    return {
      id: 0,
      merchant_id: 0,
      iswork: false,
      issend: false,
      guest_id: 0,
      timeValue: '',
      timeValueEnd: '',
      week: '',
      week2: '',
      show: false,
      time: '',
      item: {},
      minDate: new Date(),
      cate: {},
    }
  },
  mounted: function () {
    this.id = this.$route.query.id
    this.merchant_id = this.$route.query.merchant_id
    this.$store.dispatch('setMerchantId', this.merchant_id)
    this.getPre()
    this.iswork = this.isWechat()
  },
  methods: {
    isWechat() {
      var ua = navigator.userAgent.toLowerCase()
      console.log('ua.match(/wxwork/i)', ua.match(/wxwork/i))
      if (ua.match(/wxwork/i) == 'wxwork') {
        return true
      }
      return false
    },
    getPre() {
      this.$store
        .dispatch('getPre', {
          id: this.id,
          merchant_id: this.merchant_id,
          expand: 'cate',
        })
        .then((res) => {
          this.cate = res.cate
          moment.locale('zh-cn')
          if (this.cate.time_type == 'date') {
            this.timeValue = moment.unix(res.begin_time).format('YYYY-MM-DD')

            if (res.end_time > 0) {
              this.timeValueEnd = moment.unix(res.end_time).format('YYYY-MM-DD')
              this.week2 = moment.unix(res.end_time).format('dddd')
            }
          } else {
            this.timeValue = moment
              .unix(res.begin_time)
              .format('YYYY-MM-DD HH:mm')

            if (res.end_time > 0) {
              this.timeValueEnd = moment
                .unix(res.end_time)
                .format('YYYY-MM-DD HH:mm')
                
                this.week2 = moment.unix(res.end_time).format('dddd')
            }
          }

          this.week = moment.unix(res.begin_time).format('dddd')
          this.item = res
          this.guest_id = res.guest_id
        })
    },
    // 时间过滤
    filter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 10 === 0)
      }
      return options
    },
    // 出错提示
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },

    onCheckTime() {
      if (this.issend) false
      this.issend = true
      var params = {
        id: this.id,
        guest_id: this.guest_id,
        merchant_id: this.merchant_id,
      }
      this.$store.dispatch('checkTime', params).then((res) => {
        console.log('res', res)
        this.issend = false
        this.$notify({ type: 'success', message: res.message })
        this.getPre()
      })
    },
    onCancelTime() {
      var params = {
        id: this.id,
        guest_id: this.guest_id,
        merchant_id: this.merchant_id,
      }
      var that = this
      this.$store.dispatch('cancelTime', params).then((res) => {
        console.log('res', res)

        that.$notify({ type: 'success', message: res.message })
        that.getPre()
      })
    },

    // 显示弹窗
    showPopup() {
      this.show = true
      this.isLoadingShow = true
      setTimeout(() => {
        this.isLoadingShow = false
      }, 500)
    },

    // 确认选择的时间
    confirmPicker(val) {
      moment.locale('zh-cn')
      this.timeValue = moment(val).format('YYYY-MM-DD ')

      this.week = moment.unix(val).format('dddd')

      this.show = false
      this.isValue = true
    },
  },
}
</script>

<style lang="less" scoped>
.pre-view-body {
  padding: 20px 20px;
  .van-button {
    margin: 20px 0;
  }
}
</style>
