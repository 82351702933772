<template>
  <div v-if="item.is_sign == '0'">
    <sign-canvas class="sign-canvas" ref="SignCanvas" :options="options" v-model="value" />

    <div class="sign-btns">
      <van-button type="primary" block id="save" @click="saveAsImg()">保存</van-button>
      <van-button type="default" block id="save" @click="downloadSignImg()">下载</van-button>
      <van-button type="warning" block id="clear" @click="canvasClear()">清空</van-button>
    </div>
  </div>
</template>

<script>
import SC from 'sign-canvas'

export default {
  name: 'Guest',
  components: {
    SignCanvas: SC.SignCanvas
  },
  data() {
    return {
      id: 0,
      merchant_id: 0,
      item: {},
      guest_id: 0,
      value: null,
      options: {
        canvasWidth: 350, //canvas宽高 [Number] 可选
        canvasHeight: 370, //高度  [Number] 可选
        isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        isShowBorder: false, //是否显示边框 [可选]
        imgType: 'png'
      }
    }
  },
  mounted: function() {
    this.id = this.$route.query.id
    this.merchant_id = this.$route.query.merchant_id
    this.$store.dispatch('setMerchantId', this.merchant_id)
    this.getSign()
    this.iswork = this.isWechat()
  },
  created() {
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      this.options.canvasWidth = document.documentElement.clientHeight
      this.options.canvasHeight = document.documentElement.clientWidth
    },
    isWechat() {
      var ua = navigator.userAgent.toLowerCase()
      console.log('ua.match(/wxwork/i)', ua.match(/wxwork/i))
      if (ua.match(/wxwork/i) == 'wxwork') {
        return true
      }
      return false
    },
    getSign() {
      this.$store
        .dispatch('getSign', {
          id: this.id,
          merchant_id: this.merchant_id
        })
        .then(res => {
          this.item = res
          this.guest_id = res.guest_id
        })
    },
    /**
     * 清除画板
     */
    canvasClear() {
      this.$refs.SignCanvas.canvasClear()
    },

    dataURItoBlob(base64Data) {
      var byteString
      if (base64Data.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(base64Data.split(',')[1])
      else byteString = unescape(base64Data.split(',')[1])
      var mimeString = base64Data
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]
      var ia = new Uint8Array(byteString.length)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ia], { type: mimeString })
    },

    /**
     * 保存图片
     */
    saveAsImg() {
      const img = this.$refs.SignCanvas.saveAsImg()
      console.log(img)
      var img2 = img.substring(22)
      console.log('img', img2)
      if (img2) {
        var params = {
          id: this.id,
          merchant_id: this.merchant_id,
          guest_id: this.guest_id,
          image: img2,
          extend: 'png'
        }
        this.$store.dispatch('onGuestSign', params).then(res => {
          console.log('res', res)

          this.$notify({
            type: res.code == '200' ? 'success' : 'warning',
            message: res.message
          })
          this.$router.push({
            path: '/sign',
            query: { id: this.id, merchant_id: this.merchant_id }
          })
        })
      }
    },

    /**
     * 下载图片
     */
    downloadSignImg() {
      this.$refs.SignCanvas.downloadSignImg()
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.sign-canvas {
  display: block;
  margin: 20px auto;
  border: 1px dashed #f00;
}
.view-image {
  display: block;
  margin: 20px auto;
}
.sign-btns {
  margin: 10px 20px;
  .van-button {
    margin: 20px 0px;
  }
}
</style>
