<template>
  <div id="pre-view">
    <van-nav-bar title="修改预约时间" />

    <div class="pre-view-body">
      <van-form validate-first @failed="onFailed" style="margin-bottom:50px;">
        <van-cell-group>
          <van-field
            readonly
            clickable
            label="预约时间"
            name="begin_time"
            :value="timeValue"
            placeholder="点击选择时间"
            is-link
            @click="show = true"
          />

          <van-popup v-model="show" position="bottom">
            <van-datetime-picker
              type="datetime"
              v-model="time"
              title="选择时间"
              :filter="filter"
              :min-date="minDate"
              @cancel="show = false"
              @confirm="confirmPicker"
            />
          </van-popup>
        </van-cell-group>
      </van-form>

      <div v-show="!isWork">
        <div v-show="item.pre_status == '0'">
          <van-button type="primary" @click="onCheckTime" block>修改</van-button>
          <van-button type="default" block :url="'/pre?id='+id+'&merchant_id='+merchant_id">返回</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PreView',
  data() {
    return {
      id: 0,
      isWork: false,
      merchant_id: 0,
      timeValue: '',
      item: {},
      show: false,
      time: '',
      minDate: new Date()
    }
  },
  mounted: function() {
    this.id = this.$route.query.id
    this.merchant_id = this.$route.query.merchant_id
    this.getPre()
    this.isWork = this.isWechat()
  },
  methods: {
    isWechat() {
      var ua = navigator.userAgent.toLowerCase()
      console.log('ua.match(/wxwork/i)', ua.match(/wxwork/i))
      if (ua.match(/wxwork/i) == 'wxwork') {
        return true
      }
      return false
    },
    getPre() {
      this.$store
        .dispatch('getPre', {
          id: this.id,
          merchant_id: this.merchant_id
        })
        .then(res => {
          console.log(res)
          this.timeValue = moment
            .unix(res.begin_time)
            .format('YYYY-MM-DD HH:mm')
          this.item = res
        })
    },
    // 时间过滤
    filter(type, options) {
      if (type === 'minute') {
        return options.filter(option => option % 10 === 0)
      }
      return options
    },
    // 出错提示
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },

    onCheckTime() {
      if (this.time == '') {
        return this.$notify({
          type: 'danger',
          message: '请选择一个你方便的时间。'
        })
      }
      var params = {
        id: this.id,
        guest_id: this.guest_id,
        time: this.timeValue,
        merchant_id: this.merchant_id
      }
      var that = this
      this.$store.dispatch('updatelTime', params).then(res => {
        console.log('res', res)

        that.$notify({ type: 'success', message: res.message })
        that.getPre()
      })
    },

    // 显示弹窗
    showPopup() {
      this.show = true
      this.isLoadingShow = true
      setTimeout(() => {
        this.isLoadingShow = false
      }, 500)
    },

    // 确认选择的时间
    confirmPicker(val) {
      this.timeValue = moment(val).format('YYYY-MM-DD HH:mm:SS')
      this.show = false
      this.isValue = true
    }
  }
}
</script>

<style lang="less" scoped>
.pre-view-body {
  padding: 20px 20px;
  .van-button {
    margin: 20px 0;
  }
}
</style>
