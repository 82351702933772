import storage from 'store'
import { PRE, PRE_ID, MERCHANT_ID } from '../mutation-types'
import { fetchPost, fetchGet } from '@/utils/axios'

const state = {
  pre_id: 0,
  merchant_id: 0,
  pre: {},
}

const mutations = {
  [PRE_ID]: (state, id) => {
    state.pre_id = id
    storage.set(PRE_ID, id)
  },
  [PRE]: (state, res) => {
    state.pre = res
  },
  [MERCHANT_ID]: (state, id) => {
    state.merchant_id = id
    storage.set(MERCHANT_ID, id)
  },
}

const actions = {
  setMerchantId({ commit }, id) {
    return commit(MERCHANT_ID, id)
  },
  getPre({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchGet(`pre/view`, params)
        .then(
          (res) => {
            commit(PRE, res.data)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
        .catch((e) => {
          reject(e)
        })
    })
  },
  // 确认数据
  checkTime({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchPost(`pre/check`, params)
        .then((res) => {
          commit(PRE, res)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  cancelTime({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchPost(`pre/cancel`, params)
        .then((res) => {
          commit(PRE, res)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  updatelTime({ commit }, params) {
    return new Promise((resolve, reject) => {
      fetchPost(`pre/edit`, params)
        .then((res) => {
          commit(PRE, res)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export default {
  namespace: true,
  state,
  mutations,
  actions,
}
