export const PRE = 'PRE'
export const PRE_ID = 'PRE_ID'
export const MERCHANT_ID = 'MERCHANT_ID'

export const SIGN = 'SIGN'
export const SIGN_ID = 'SIGN_ID'

export const UPLOAD = 'UPLOAD'

export const TAKE = 'TAKE'
export const TAKE_ID = 'TAKE_ID'
