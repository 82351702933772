<template>
  <div id="pre-view">
    <van-nav-bar title="签字" />

    <div v-if="cate.sign_template && !item.sign">
      <img :src="cate.sign_template" style="width:100%" />
    </div>

    <div v-if="item.sign">
      <img :src="item.sign" style="width:100%" />
    </div>

    <div class="pre-view-body">
      <div v-show="!iswork">
        <div v-show="item.is_sign == '0'">
          <van-button
            type="primary"
            :to="{
              path: '/sign/guest',
              query: { id: item.id, merchant_id: item.merchant_id },
            }"
            block
          >确认签字</van-button>
        </div>
      </div>
      <van-cell>
        <template #title>{{ item.content }}</template>
      </van-cell>
      <van-cell>
        <template #title>
          <div class="van-uploader">
            <div class="van-uploader__wrapper">
              <div class="van-uploader__preview" v-for="(image, index) in item.covers" :key="index">
                <div
                  class="van-image van-uploader__preview-image"
                  @click="showGallery(item.covers)"
                >
                  <van-image :src="image" class="van-image__img" style="object-fit: cover;">
                    <template v-slot:error>加载失败</template>
                  </van-image>
                </div>
              </div>

              <div v-show="item.sign" class="van-uploader__preview">
                <div
                  class="van-image van-uploader__preview-image"
                  @click="showGallery([item.sign])"
                >
                  <van-image :src="item.sign" class="van-image__img" style="object-fit: cover;">
                    <template v-slot:error>
                      <van-icon name="video" size="30" />
                    </template>
                  </van-image>
                </div>
              </div>

              <div v-show="item.video" class="van-uploader__preview">
                <div class="van-image van-uploader__preview-image" @click="onVideo(item)">
                  <van-image class="van-image__img" style="object-fit: cover;">
                    <template v-slot:error>
                      <van-icon name="video" size="30" />
                    </template>
                  </van-image>
                </div>
              </div>
              <van-popup v-model="showVideo">
                <vueMiniPlayer :video="video" />
              </van-popup>
            </div>
          </div>
        </template>
      </van-cell>

      <van-form validate-first @failed="onFailed" style="margin-bottom:50px;">
        <van-cell-group>
          <van-field readonly label="发起时间" :value="timeValue" />
          <van-field readonly label="发起人" :value="item.member_name" />
          <van-field readonly label="分类" :value="item.cate_name" />
          <van-field readonly label="是否签名" :value="item.is_sign == '1' ? '是' : '否'" />
        </van-cell-group>
      </van-form>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import moment from 'moment'
export default {
  name: 'PreView',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data() {
    return {
      id: 0,
      video: {},
      cate: {},
      showVideo: false,
      merchant_id: 0,
      iswork: false,
      guest_id: 0,
      timeValue: '',
      show: false,
      time: '',
      item: {},
      minDate: new Date()
    }
  },
  mounted: function() {
    this.id = this.$route.query.id
    this.merchant_id = this.$route.query.merchant_id
    this.$store.dispatch('setMerchantId', this.merchant_id)
    this.getSign()
    this.iswork = this.isWechat()
  },
  methods: {
    showGallery(item) {
      ImagePreview({ closeOnPopstate: true, images: item })
    },
    onVideo(item) {
      this.showVideo = true
      this.video.url = item.video
      console.log('video', item.video)
    },
    isWechat() {
      var ua = navigator.userAgent.toLowerCase()
      console.log('ua.match(/wxwork/i)', ua.match(/wxwork/i))
      if (ua.match(/wxwork/i) == 'wxwork') {
        return true
      }
      return false
    },
    getSign() {
      this.$store
        .dispatch('getSign', {
          id: this.id,
          merchant_id: this.merchant_id
        })
        .then(res => {
          console.log(res)
          this.timeValue = moment
            .unix(res.created_at)
            .format('YYYY-MM-DD HH:mm')
          this.item = res
          this.guest_id = res.guest_id
          this.cate = res.cate
        })
    },
    // 时间过滤
    filter(type, options) {
      if (type === 'minute') {
        return options.filter(option => option % 10 === 0)
      }
      return options
    },
    // 出错提示
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
    },

    onCheckTime() {
      var params = {
        id: this.id,
        guest_id: this.guest_id,
        merchant_id: this.merchant_id
      }
      this.$store.dispatch('checkTime', params).then(res => {
        console.log('res', res)
        this.$notify({ type: 'success', message: res.message })
        this.getPre()
      })
    },
    onCancelTime() {
      var params = {
        id: this.id,
        guest_id: this.guest_id,
        merchant_id: this.merchant_id
      }
      var that = this
      this.$store.dispatch('cancelTime', params).then(res => {
        console.log('res', res)

        that.$notify({ type: 'success', message: res.message })
        that.getPre()
      })
    },

    // 显示弹窗
    showPopup() {
      this.show = true
      this.isLoadingShow = true
      setTimeout(() => {
        this.isLoadingShow = false
      }, 500)
    },

    // 确认选择的时间
    confirmPicker(val) {
      this.timeValue = moment(val).format('YYYY-MM-DD HH:mm:SS')
      this.show = false
      this.isValue = true
    }
  }
}
</script>

<style lang="less" scoped>
.pre-view-body {
  padding: 10px 20px;
  .van-button {
    margin: 10px 0;
  }
}
</style>
